<template>
  <div>
    <b-table :fields="fields"
             :items="[proposalFromValue]"
             class="table-responsive"
             head-variant="light"
             responsive
             striped>
      <template v-slot:cell(actions)="row">
        <action-download-pdf :proposal="row.item.proposal"/>
      </template>
    </b-table>

    <label class="font-weight-bold">Technical comments</label>
    <b-form-textarea :rows="3"
                     :value="technical_comment"
                     readonly></b-form-textarea>

    <review_form v-model="proposalFromValue"
                 :shifts_config="shifts_config"
                 @closeModal="close_modal_emit">
    </review_form>
  </div>
</template>

<script>
  import ActionDownloadPdf from '@/components/actions/ActionDownloadPdf';
  import review_form from '@/components/evaluation/review/review_form';

  export default {
    name: 'review_modal',
    components: {
      ActionDownloadPdf,
      review_form,
    },
    props: {
      fields: {
        type: Array,
        default: () => [],
      },
      shifts_config: {
        type: Array,
        default: () => [],
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data() {
      return { proposalFromValue: this.value };
    },
    computed: {
      technical_comment() {
        return this.get_deep_prop(this.value, 'proposal.technical_evaluation.comments');
      },
    },
    watch: {
      proposalFromValue: {
        handler() {
          this.$emit('input', this.proposalFromValue);
        },
        deep: true,
      },
      value() {
        this.proposalFromValue = this.value;
      },
    },
  };
</script>

<style scoped>

</style>
